<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
    >
      <template #content>
        <div class="pa-4">
          <v-text-field
            hide-details
            label="Nome do perfil"
            v-model="form.profileName"
          ></v-text-field>
        </div>

        <h3 class="pa-4 mt-2">Permissões do perfil</h3>

        <v-expansion-panels class="pa-4" v-model="panel">
          <v-expansion-panel
            :key="permissionGroup.id"
            v-for="permissionGroup in permissionGroups"
          >
            <v-expansion-panel-header>
              {{ permissionGroup.name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item-group multiple>
                <v-list-item
                  :key="permission.id"
                  v-for="permission in permissionGroup.Permissions"
                >
                  <v-list-item-action>
                    <v-checkbox
                      v-model="form.selectedPermissions"
                      :value="permission.id"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      permission.permissionName
                    }}</v-list-item-title>
                    <!-- <v-list-item-subtitle>Notify me about updates to apps or games that I downloaded</v-list-item-subtitle> -->
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>

      <template #actions></template>
    </BaseForm>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    loadingPermissions: false,

    form: { selectedPermissions: [] },
    panel: 0,
    permissionGroups: [],
    actionAfterOperation: null,
  }),
  computed: {},
  methods: {
    validate() {
      const errors = [];

      if (!this.form.profileName)
        errors.push("Informe o nome do perfil de usuário");

      if (!this.form.selectedPermissions.length)
        errors.push(
          "Selecione pelo menos uma permissão para o perfil de usuário"
        );

      return errors;
    },
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        await this.$axios.post(`/users/profiles`, this.form);

        this.clearForm();

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(
          `/users/profiles/${this.$route.params.id}`,
          this.form
        );

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async loadPermissions() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(`/permissions`);

        this.setPermissions(response.data);
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },
    setPermissions(data) {
      this.permissionGroups = [...data];
    },
    async loadProfileData() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(
          `/users/profiles/${this.$route.params.id}`
        );

        this.setProfileData(response.data);
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },
    setProfileData(data) {
      const cpData = { ...data };

      const { Permissions: permissions, name } = cpData;

      this.form.selectedPermissions = permissions.map((p) => p.id);
      this.form.profileName = name;
    },
    afterOperation() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
    },
    clearForm() {
      this.form = { selectedPermissions: [] };
    },
  },
  created() {
    this.loadPermissions();
    if (this.mod === "update") this.loadProfileData();
  },
};
</script>

<style></style>
